<template>
  <div style="padding: 20px">
    <div class="query-list" style="padding-bottom: 20px">
      <el-row :gutter="20">
        <el-col :span="18" :offset="0">
          <el-row :gutter="20">
            <el-col :span="6" :offset="0">
              <el-input
                @keyup.enter.native="handleClickSearch"
                v-model="ucid"
                placeholder="请输入用户UCID"
                size="normal"
              ></el-input>
            </el-col>
            <!-- <el-col :span="6" :offset="0">
              <el-input v-model="mobile" placeholder="请输入手机号" size="normal"></el-input>
            </el-col>
            -->
            <!-- <el-col :span="6" :offset="0"
              ><el-input
                v-model="email"
                placeholder="请输入邮箱"
                size="normal"
              ></el-input
            ></el-col> -->
          </el-row>
        </el-col>
        <el-col :span="6" :offset="0">
          <el-button type="primary" size="default" @click="handleClickSearch">
            查询
          </el-button>
          <el-button type="info" size="default" @click="handleClickReset">
            重置
          </el-button>
        </el-col>
      </el-row>
    </div>

    <el-table :data="list" border stripe row-key="seed">
      <!-- sortable="custom" -->
      <el-table-column label="UCID" prop="ucid"> </el-table-column>
      <el-table-column label="钱包索引" prop="user_index"></el-table-column>
      <el-table-column label="持有NFT数量" prop="hold_nft_count">
        <template slot-scope="scope">
          {{ holdCountMap[scope.row.user_index] || 0 }}
        </template>
      </el-table-column>
      <el-table-column label="钱包创建时间" prop="user_index">
        <template slot-scope="scope">
          <div>{{ scope.row.user_index | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column label="钱包是否有问题" prop="user_index">
        <template slot-scope="scope">
          <div>{{ scope.row | seedCheck }}</div>
        </template>
      </el-table-column>
      <el-table-column label="钱包是否有问题" prop="user_index">
        <template slot-scope="scope">
          <div>{{ scope.row | seedCheck }}</div>
        </template>
      </el-table-column>
      <el-table-column label="钱包xpub" prop="xpub"></el-table-column>
    </el-table>
    <!-- <div style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div> -->
  </div>
</template>

<script>
import { getUserSeeds, getUserWorks } from "@/api/nft.js";
import dayjs from "dayjs";

export default {
  components: {},
  data() {
    return {
      // page: 1,
      // pageSize: 20,
      // total: 0,
      list: [],
      // 查询参数
      ucid: "",
      mobile: "",
      email: "",
      holdCountMap: {},
    };
  },
  filters: {
    formatDate: function(value) {
      if (value == 0) return "0号钱包";
      return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
    },
    seedCheck: function(row) {
      if (row.seed.includes("bsv_coin_regular")) {
        const arr = row.seed.split("_");
        const last = arr.at(-1);
        if (last != "0") {
          return "可能有";
        }
      }
      return "否";
    },
  },
  mounted() {
    const search = new URLSearchParams(window.location.search);
    const id = search.get("id");
    if (id) {
      this.ucid = id;
      this.fetchData();
    }
  },
  methods: {
    async fetchData() {
      // if (!this.ucid) return;
      const params = {
        ucid: this.ucid,
      };

      // 钱包的编号, 创建时间, 持有数量, 是否有问题
      try {
        const walletRes = await getUserSeeds(params);
        // console.log(walletRes.data.data);
        this.list = walletRes.data.data;

        const userIndexMap = {};
        const workRes = await getUserWorks(params);
        // console.log(workRes.data.data);

        workRes.data.data.forEach((item) => {
          if (userIndexMap[item.owner.user_index] === undefined) {
            userIndexMap[item.owner.user_index] = 1;
          } else {
            userIndexMap[item.owner.user_index] += 1;
          }
        });

        this.holdCountMap = userIndexMap;
      } catch (error) {
        // console.log(error);
      }

      // 1269973144077078528
    },

    handleClickSearch() {
      this.fetchData();
    },

    handleClickReset() {
      this.ucid = "";
      this.mobile = "";
      this.email = "";
      this.list = [];
    },
    handleGoOrderbuyer(row) {
      this.$router.push("/nft_management/trade?buyer_id=" + row.ucid);
    },

    handleGoOrderSeller(row) {
      this.$router.push("/nft_management/trade?seller_id=" + row.ucid);
    },
    handleGoNFT(row) {
      this.$router.push("/nft_management?owner_id=" + row.ucid);
    },
    handleGoWidthDraw(row) {
      this.$router.push("/nft_orders/withdraw?ucid=" + row.ucid);
    },
  },
};
</script>
